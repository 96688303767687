<template>
  <body>
  <header>
    <h1>ВАШИ РЕЗУЛЬТАТЫ:</h1>
  </header>
  <div class="results">
    <div class="results-left">
      <!-- Диаграмма для десктопа -->
      <div class="diagram">
        <img src="../assets/images/instructions-diagram.svg" alt="diagram" class="diagram-circle" />
        <!-- Изображение архетипов -->
        <img
            v-for="item in archetypesDiagram"
            :key="item.key"
            :src="require(`@/assets/images/diagram/${item.img}`)"
            alt="diagram"
            :class="item.cssClass"
            :style="{ zIndex: diagram[item.key].index - 1 }"
        />
        <!-- Блоки pouring -->
        <div
            v-for="item in archetypesDiagram"
            :key="item.key + '-pouring'"
            :class="`diagram-circle-pouring ${item.cssClass}-block`"
            :style="{
              zIndex: diagram[item.key].index,
              boxShadow: '0 0 0 ' + diagram[item.key].width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <!-- Числовые значения -->
        <div class="diagram-circle-numbers">
          <p v-for="item in archetypesDiagram" :key="item.key + '-num'">
            {{ archetypes[item.key] }}
          </p>
        </div>
      </div>
      <!-- Диаграмма для мобильных устройств -->
      <div class="main-mobile-diagram" style="display: none;">
        <img src="../assets/images/instructions-diagram.svg" alt="diagram" class="main-mobile-diagram-circle" />
        <img
            v-for="item in archetypesDiagram"
            :key="item.key + '-mobile-img'"
            :src="require(`@/assets/images/diagram/${item.img}`)"
            alt="diagram"
            :class="item.cssClass"
            :style="{ zIndex: diagram[item.key].index - 1 }"
        />
        <div
            v-for="item in archetypesDiagram"
            :key="item.key + '-mobile-pouring'"
            :class="`diagram-circle-pouring ${item.cssClass}-block`"
            :style="{
              zIndex: diagram[item.key].index,
              boxShadow: '0 0 0 ' + diagram[item.key].mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div class="diagram-circle-numbers">
          <p v-for="item in archetypesDiagram" :key="item.key + '-mobile-num'">
            {{ archetypes[item.key] }}
          </p>
        </div>
      </div>
    </div>

    <div class="results-right">
      <div class="results-right-banner">
        <img src="../assets/images/results-anna-with-book1.webp" alt="anna">
        <div class="results-right-banner-block">
          <h3>
            ПОЛУЧИТЕ ПЕРСОНАЛЬНЫЙ <br>РАЗБОР РЕЗУЛЬТАТОВ ТЕСТА
          </h3>
          <hr class="hr">
          <p>
            &#10004; <b>7 расширенных показателей</b> диагностики вашей Личности <br><br>
            &#10004; <b>Я помогу вам разобраться</b> как правильно применить ваши архетипы под текущую задачу <br><br>
          </p>
          <a href="https://zavadskaya.online/audit" target="_blank" class="button">
            РАСШИФРОВКА ТЕСТА
          </a>
        </div>
      </div>
      <div class="results-right-mobile-video" style="display: none;">
        <div class="results-right-mobile-video-block">
          <h3>
            ПОЛУЧИТЕ ПЕРСОНАЛЬНЫЙ РАЗБОР РЕЗУЛЬТАТОВ ТЕСТА
          </h3>
          <hr class="hr">
          <p>
            Я лично проверю ваш блог и запишу аудио-разбор:<br><br>
            &#10004; <b>7 расширенных показателей</b> диагностики вашей Личности <br><br>
            &#10004; <b>Я помогу вам разобраться</b> как правильно применить ваши архетипы под текущую задачу
          </p>
          <a href="https://zavadskaya.online/audit" target="_blank" class="button">
            РАСШИФРОВКА ТЕСТА
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Мобильный блок для топ-архетипов -->
  <div class="bottom-results-mobile-archetypes" style="display: none;">
    <h3 class="center-h2-mobile">Ваши сильные архетипы:</h3>
    <div class="bottom-results-mobile-archetypes-info">
      <div
          class="bottom-results-mobile-archetypes-info-block"
          v-for="(value, key) in mobileBlocks"
          :key="key"
      >
        <p>{{ archetypes[key] }}</p>
        <hr class="hr">
        <div>
          <div class="bottom-results-mobile-archetypes-info-block-div">
            <img :src="`https://annazavadskaya.ru/icons/mobile/${value.image}`" :alt="key">
          </div>
          <p class="bottom-results-mobile-archetypes-info-block-p" v-html="value.name"></p>
        </div>
        <Transition name="mobile-block">
          <div class="bottom-results-mobile-archetypes-describe">
            <div :style="{ right: '268px' }"></div>
            <p>
              {{ value.text }}<br>
              <a :href="value.link" target="_blank"><b>Перейти к описанию >>></b></a>
            </p>
          </div>
        </Transition>
      </div>
    </div>
  </div>

  <!-- Новый мобильный блок для минимальных (слабых) архетипов -->
  <div class="bottom-results-mobile-archetypes" style="display: none;">
    <h3 class="center-h2-mobile">Какие архетипы важно активировать:</h3>
    <div class="bottom-results-mobile-archetypes-info">
      <div
          class="bottom-results-mobile-archetypes-info-block"
          v-for="(value, key) in minMobileBlocks"
          :key="key"
      >
        <p>{{ value.points }}</p>
        <hr class="hr">
        <div>
          <div class="bottom-results-mobile-archetypes-info-block-div">
            <img :src="`https://annazavadskaya.ru/icons/mobile/${value.image}`" :alt="key">
          </div>
          <p class="bottom-results-mobile-archetypes-info-block-p" v-html="value.name"></p>
        </div>
        <Transition name="mobile-block">
          <div class="bottom-results-mobile-archetypes-describe">
            <div :style="{ right: '268px' }"></div>
            <p>
              {{ value.text }}<br>
              <a :href="value.link" target="_blank"><b>Перейти к описанию >>></b></a>
            </p>
          </div>
        </Transition>
      </div>
    </div>
  </div>

  <!-- Блок для ПК: топ-архетипы -->
  <h2 class="center-h2">Ваши сильные архетипы:</h2>
  <div class="archetypes">
    <div class="archetypes-block" v-for="(value, key) in enabledBlocks" :key="key">
      <div class="archetypes-block-image">
        <img :src="`https://annazavadskaya.ru/icons/${value.image}`" :alt="key">
      </div>
      <hr class="hr">
      <p class="archetypes-block-percent">{{ value.points }}</p>
      <h3 v-html="value.name + ':'"></h3>
      <p class="archetypes-block-p">
        {{ value.text }}<br>
        <a :href="value.link" target="_blank"><b>Перейти к описанию >>></b></a>
      </p>
    </div>
  </div>

  <!-- Блок для ПК: минимальные архетипы -->
  <h2 class="center-h2">Какие архетипы важно активировать:</h2>
  <div class="archetypes">
    <div class="archetypes-block" v-for="block in minBlocks" :key="block.key">
      <div class="archetypes-block-image">
        <img :src="`https://annazavadskaya.ru/icons/${block.image}`" :alt="block.key">
      </div>
      <hr class="hr">
      <p class="archetypes-block-percent">{{ block.points }}</p>
      <h3 v-html="block.name + ':'"></h3>
      <p class="archetypes-block-p">
        {{ block.text }}<br>
        <a :href="block.link" target="_blank"><b>Перейти к описанию >>></b></a>
      </p>
    </div>
  </div>

  <div class="book">
    <hr class="hr">
    <div class="book-light"></div>
    <img src="../assets/images/results-anna-bottom.webp" alt="Анна Завадская" class="book-img">
    <div class="book-set">
      <div class="book-set-block">
        <p>
          Присоединяйтесь в телеграм, чтобы узнать как через архетипы двигаться к своим целям в потоке - легко, на энергии, в любви к себе и миру!
        </p>
        <a href="https://t.me/+alyfiQj43tphYzc6" target="_blank" class="button">ПЕРЕЙТИ В ТГ</a>
      </div>
    </div>
    <hr class="hr book-hr">
  </div>

  <div class="book-mobile" style="display: none;">
    <p>
      Присоединяйтесь в телеграм, чтобы узнать как через архетипы двигаться к своим целям в потоке - легко, на энергии, в любви к себе и миру!
    </p>
    <img src="../assets/images/mobile/results-arrow-bottom.svg" alt="arrow">
    <a href="https://t.me/+alyfiQj43tphYzc6" target="_blank" class="button">ПЕРЕЙТИ В ТГ</a>
    <div class="book-mobile-anna">
      <div class="book-mobile-anna-light"></div>
      <img src="../assets/images/mobile/results-anna.webp" alt="anna" class="book-mobile-anna-img">
    </div>
  </div>

  <footer>
    <div class="footer">
      <div class="footer-text">
        © 2019 zavadskaya.online <br>
        Авторские права защищены <br>
        Копирование запрещено
      </div>
      <div class="footer-links">
        <a href="https://t.me/zavadskaya12D" target="_blank">
          <img src="../assets/images/results-tg.svg" alt="Telegram" class="footer-links">
        </a>
      </div>
    </div>
  </footer>

  <div class="mobile-links" style="display: none;">
    <div>
      <a href="https://t.me/zavadskaya12D" target="_blank">
        <img src="../assets/images/results-tg.svg" alt="Telegram">
      </a>
    </div>
  </div>
  <transition name="popup">
    <popup v-if="isVisiblePopup" @closePopup="closePopup" />
  </transition>
  </body>
</template>

<script>
import router from "@/router";
import popup from "@/components/PopupPayment";

export default {
  name: "ResultsCutView",
  components: { popup },
  data() {
    return {
      // Массив для циклического рендеринга диаграммы
      archetypesDiagram: [
        { key: "nevin", img: "nevin.svg", cssClass: "diagram-circle-nevin" },
        { key: "isk", img: "isk.svg", cssClass: "diagram-circle-isk" },
        { key: "mudr", img: "intel.svg", cssClass: "diagram-circle-intel" },
        { key: "hero", img: "hero.svg", cssClass: "diagram-circle-hero" },
        { key: "bunt", img: "izmen.svg", cssClass: "diagram-circle-izmen" },
        { key: "mag", img: "duh.svg", cssClass: "diagram-circle-duh" },
        { key: "zabot", img: "zabot.svg", cssClass: "diagram-circle-zabot" },
        { key: "tvor", img: "tvor.svg", cssClass: "diagram-circle-tvor" },
        { key: "prav", img: "prav.svg", cssClass: "diagram-circle-prav" },
        { key: "mal", img: "mal.svg", cssClass: "diagram-circle-mal" },
        { key: "love", img: "love.svg", cssClass: "diagram-circle-love" },
        { key: "jester", img: "jester.svg", cssClass: "diagram-circle-jester" },
      ],
      mobileBlocks: {},
      blocks: {
        bunt: {
          name: "Бунтарь",
          points: 0,
          text: 'Стратегия кардинальных изменений, помогает разрушать неработающее и устаревшее. Вносит перемены, тотальное освобождение, квантовый рост.',
          link: 'https://zavadskaya.online/arxetip-buntar-izgoj',
          image: "results-bunt.svg",
          isEnabled: false
        },
        hero: {
          name: "Герой",
          points: 0,
          text: 'Стратегия достижений - источник вашей активности, смелости, уверенности в себе. Помогает вам проходить любые препятствия и достигать своих целей.',
          link: 'https://zavadskaya.online/arxetip-geroj-voin',
          image: "results-hero.svg",
          isEnabled: false
        },
        isk: {
          name: "Искатель",
          points: 0,
          text: 'Стратегия развития, свободы, гибкой подстройки под меняющиеся условия. Помогает вам идти в ногу со временем и использовать новые возможности.',
          link: 'https://zavadskaya.online/arxetip-iskatel-strannik',
          image: "results-isk.svg",
          isEnabled: false
        },
        jester: {
          name: "Шут",
          points: 0,
          text: 'Стратегия развлечений и ярких впечатлений. Помогает снижать напряжение, применять игровой подход к решению задач, смеяться и радоваться жизни.',
          link: 'https://zavadskaya.online/arxetip-shut',
          image: "results-shut.svg",
          isEnabled: false
        },
        love: {
          name: "Любовник",
          points: 0,
          text: 'Стратегия гедонизма и наслаждения жизнью. Помогает вам быть привлекательными, сексуальными, притягивать внимание и восхищение окружающих.',
          link: 'https://zavadskaya.online/arxetip-lyubovnik-estet',
          image: "results-love.svg",
          isEnabled: false
        },
        mag: {
          name: "Маг",
          points: 0,
          text: 'Стратегия осознанности и работы с непроявленным. Помогает вам управлять состояниями, трансформировать мышление, влиять на происходящее.',
          link: 'https://zavadskaya.online/arxetip-mag-volshebnik',
          image: "results-mag.svg",
          isEnabled: false
        },
        mal: {
          name: "Славный Малый",
          points: 0,
          text: 'Стратегия дружеского общения на равных. Помогает вам создавать успешное взаимодействие с людьми, широкий круг общения, синергию в партнерстве.',
          link: 'https://zavadskaya.online/arxetip-slavnyj-malyj',
          image: "results-mal.svg",
          isEnabled: false
        },
        mudr: {
          name: "Мудрец",
          points: 0,
          text: 'Стратегия мудрости, аналитики. Помогает принимать взвешенные решения, видеть всю картину в целом, исследовать взаимосвязи причин и следствий.',
          link: 'https://zavadskaya.online/arxetip-mudrec-filosof',
          image: "results-mudr.svg",
          isEnabled: false
        },
        nevin: {
          name: "Невинный",
          points: 0,
          text: 'Стратегия легкости и простоты, спонтанности и позитива. Помогает вам жить в согласии с собой, радоваться каждому моменту и вдохновлять окружающих.',
          link: 'https://zavadskaya.online/arxetip-nevinnyj-prostodushnyj',
          image: "results-nevin.svg",
          isEnabled: false
        },
        prav: {
          name: "Правитель",
          points: 0,
          text: 'Стратегия управления, контроля и власти. Помогает создавать финансовую стабильность, процветание, системно выстраивать благополучие.',
          link: 'https://zavadskaya.online/arxetip-pravitel',
          image: "results-prav.svg",
          isEnabled: false
        },
        tvor: {
          name: "Творец",
          points: 0,
          text: 'Стратегия создания нового, нестандартных решений и креатива. Рождает творческий импульс и помогает уникально самовыражаться через свое дело жизни.',
          link: 'https://zavadskaya.online/arxetip-tvorec',
          image: "results-tvor.svg",
          isEnabled: false
        },
        zabot: {
          name: "Заботливый",
          points: 0,
          text: 'Стратегия внимательного отношения, заботы и помощи. Помогает вам окружать близких комфортом, уютом, создавать теплую поддерживающую атмосферу.',
          link: 'https://zavadskaya.online/arxetip-zabotlivyj-xranitel',
          image: "results-zabot.svg",
          isEnabled: false
        },
      },
      currentTriangle: 0,
      currentDisplay: { one: false },
      mobileTriangle: [264, 148, 34],
      mobileState: -1,
      session: this.$route.params.session,
      archetypes: {
        nevin: 0,
        mal: 0,
        hero: 0,
        zabot: 0,
        isk: 0,
        bunt: 0,
        love: 0,
        tvor: 0,
        prav: 0,
        mag: 0,
        mudr: 0,
        jester: 0
      },
      diagram: {
        nevin: { index: 1, width: 1, mobileWidth: 1 },
        mal: { index: 1, width: 1, mobileWidth: 1 },
        hero: { index: 1, width: 1, mobileWidth: 1 },
        zabot: { index: 1, width: 1, mobileWidth: 1 },
        isk: { index: 1, width: 1, mobileWidth: 1 },
        bunt: { index: 1, width: 1, mobileWidth: 1 },
        love: { index: 1, width: 1, mobileWidth: 1 },
        tvor: { index: 1, width: 1, mobileWidth: 1 },
        prav: { index: 1, width: 1, mobileWidth: 1 },
        mag: { index: 1, width: 1, mobileWidth: 1 },
        mudr: { index: 1, width: 1, mobileWidth: 1 },
        jester: { index: 1, width: 1, mobileWidth: 1 },
        currentIndex: 2,
        circle: [135, 120, 109, 98, 86, 74, 62, 51, 39, 28, 0],
        circleMobile: [73, 64, 57, 50, 43, 36, 29, 22, 15, 8, 0],
      },
      isVisiblePopup: false,
    };
  },
  computed: {
    enabledBlocks() {
      return Object.entries(this.blocks)
          .filter(([, block]) => block.isEnabled)
          .sort(([, a], [, b]) => b.points - a.points)
          .reduce((acc, [key, block]) => {
            acc[key] = block;
            return acc;
          }, {});
    },
    minBlocks() {
      // Для ПК берём 4 архетипа с минимальными баллами
      return Object.entries(this.blocks)
          .sort(([, a], [, b]) => a.points - b.points)
          .slice(0, 4)
          .map(([key, block]) => ({ key, ...block }));
    },
    minMobileBlocks() {
      // Для мобильной версии берём 3 архетипа с минимальными баллами
      return Object.entries(this.blocks)
          .sort(([, a], [, b]) => a.points - b.points)
          .slice(0, 3)
          .map(([key, block]) => ({ key, ...block }));
    }
  },
  methods: {
    getMobileBlocks() {
      const enabledItems = Object.entries(this.blocks)
          .filter(([, item]) => item.isEnabled)
          .sort(([, a], [, b]) => b.points - a.points)
          .slice(0, 3);
      if (enabledItems.length >= 4) {
        enabledItems.splice(3, 1);
      }
      return Object.fromEntries(enabledItems);
    },
    closePopup() {
      this.isVisiblePopup = false;
    },
    async getResult() {
      try {
        const res = await this.axios.post("https://dev.annazavadskaya.ru:3000/api/results/", { id: this.session });
        // Сохраняем баллы по каждому архетипу
        this.archetypes = { ...res.data };

        // Обновляем баллы для всех блоков
        Object.keys(this.blocks).forEach(key => {
          this.blocks[key].points = this.archetypes[key];
        });

        // Получаем 4 архетипа с максимальными баллами (топ)
        const conclusion = findTop(this.archetypes, 4);
        conclusion.forEach(key => {
          this.blocks[key].isEnabled = true;
        });

        this.mobileBlocks = this.getMobileBlocks();

        // Если ключевые архетипы равны 0 – перенаправляем на home
        if (
            this.archetypes.nevin === 0 &&
            this.archetypes.mal === 0 &&
            this.archetypes.hero === 0 &&
            this.archetypes.zabot === 0
        ) {
          return router.push({ name: 'home' });
        }

        calculateDiagram(this);
      } catch (error) {
        console.error("Ошибка получения результата:", error);
      }
    }
  },
  async beforeMount() {
    await this.getResult();
    if (this.$route.params.payment) {
      this.isVisiblePopup = true;
    }
  }
};

function findTop(obj, topVal) {
  return Object.keys(obj)
      .sort((a, b) => obj[b] - obj[a])
      .slice(0, topVal);
}

function calculateDiagram(app) {
  const keys = ["nevin", "mal", "hero", "zabot", "isk", "bunt", "love", "prav", "tvor", "mag", "mudr", "jester"];
  const floors = {};
  keys.forEach(key => {
    floors[key] = Math.floor(app.archetypes[key] / 10);
  });
  const array = keys.map(key => floors[key]);
  const arrayText = [...keys];
  for (let i = 0; i < 11; i++) {
    const minVal = Math.min(...array);
    const indices = [];
    if (minVal !== 15) {
      array.forEach((el, index, arr) => {
        if (el === minVal) {
          indices.push(index);
          arr[index] = 15;
        }
      });
    }
    if (indices.length) {
      if (indices.length > 1) {
        indices.forEach(idx => {
          app.diagram[arrayText[idx]].index = app.diagram.currentIndex + 2;
        });
        app.diagram.currentIndex += 2;
      } else {
        app.diagram[arrayText[indices[0]]].index = app.diagram.currentIndex;
      }
    }
    app.diagram.currentIndex += 2;
  }
  keys.forEach(key => {
    app.diagram[key].width = app.diagram.circle[floors[key]];
    app.diagram[key].mobileWidth = app.diagram.circleMobile[floors[key]];
  });
}
</script>

<style lang="scss" scoped>
@import "/src/assets/css/resultsCut.scss";
</style>
