<template>
  <body>
    <header class="header">
      <div class="header-flex">
        <h2 class="header-text-p header-text-p-legendary">УЗНАЙТЕ ВАШИ ВЕДУЩИЕ ЖИЗНЕННЫЕ СТРАТЕГИИ</h2>
        <img
            src="../assets/images/header-text-with-anna.webp"
            class="header-image"
            alt="Анна"
        />
        <img
            src="../assets/images/mobile/anna.webp"
            class="header-image-mobile"
            alt="Анна"
        />
        <img
            src="../assets/images/mobile/text-header.svg"
            class="header-image-mobile-text"
            alt="Тест на архетипы 12D"
            style="display: none;"
        />
        <p class="header-text-p header-text-p-magic">
          Более 300.000 человек уже прошли тест
        </p>
        <p class="header-text-p header-text-p-energy">
          Как применить знания об архетипах личности для жизни и бизнеса
        </p>
        <div class="header-flex-button">
          <a @click="redirectToInstructions" class="header-flex-button-a">
            <img src="../assets/images/button.svg" alt="button">
            <p class="header-flex-button-p">Начать</p>
          </a>
          <img src="../assets/images/arrow.svg" alt="Arrow" class="header-flex-button-arrow">
        </div>
        <p class="header-text-p header-text-p-gift">
          <span>
            ПРОЙДИТЕ ТЕСТ
          </span>
          <br>
          и получите <span>персональный разбор результатов</span>
        </p>
        <div class="header-flex-text-mobile" style="display: none">
          <p class="header-flex-text-mobile-name">Анна Завадская</p>
          <p class="header-flex-text-mobile-desc">
            Эксперт по архетипам, автор методологии «Архетипы 12D»
          </p>
        </div>
        <div class="header-flex-hexagon">
          <img src="../assets/images/hexagon.svg" alt="hexagon">
          <div class="header-flex-hexagon-text">
            <p class="header-flex-hexagon-text-name">Анна Завадская</p>
            <p class="header-flex-hexagon-text-desc">
              Эксперт по архетипам, автор методологии «Архетипы 12D»
            </p>
          </div>
        </div>
      </div>
    </header>
    <div class="center">
      <div class="center-desc">
        <p class="center-desc-mobile-energy" style="display: none;">
          Как применить знания об архетипах личности для жизни и бизнеса
        </p>
        <a @click="redirectToInstructions" class="center-desc-mobile-button" style="display: none;">
          <img src="../assets/images/mobile/button.svg" alt="button" class="center-desc-mobile-button-img">
          <p class="center-desc-mobile-button-p">
            Начать
          </p>
        </a>
        <img
            src="../assets/images/mobile/arrow.svg"
            alt="arrow"
            class="center-desc-mobile-arrow"
            style="display: none;"
        >
        <p class="center-desc-mobile-gift" style="display: none;">
          <span>
            ПРОЙДИТЕ ТЕСТ
          </span>
          <br>
          и получите <span>персональный разбор результатов</span>
        </p>
        <img
            src="../assets/images/mobile/rhombus.svg"
            alt="rhombus"
            class="center-desc-mobile-rhombus"
            style="display: none"
        >
        <p class="center-desc-p">
          Тест на Архетипы покажет - как работают все ваши 12 архетипов личности.<br><br>
          Это стратегии поведения, которые отвечают за успех во всех сферах жизни: любовь, здоровье, деньги, самореализация.<br><br>
          Какие архетипы уже работают на вас, а какие пока спят и блокируют ваш потенциал.<br><br>
          Понимание своих Архетипов и их активация - помогут вам двигаться к своим целям легко, на энергии, в согласии с собой и миром!<br><br>
        </p>
      </div>
    </div>
    <div class="results">
      <img src="../assets/images/circle.webp" alt="circle" class="results-circle">
      <img
          src="../assets/images/mobile/circle.webp"
          alt="circle"
          class="results-mobile-circle"
          style="display: none;"
      >
      <div class="results-eclipse"></div>
      <div class="results-head">
        <img src="../assets/images/arrow-left.svg" alt="Arrow left">
        <p class="results-head-p">ПРОЙДИТЕ ТЕСТ, ЧТОБЫ УЗНАТЬ:</p>
        <img src="../assets/images/arrow-right.svg" alt="Arrow right">
      </div>
      <div class="results-table">
        <div class="results-table-text">
          <div class="results-table-text-block">
            <img src="../assets/images/check.svg" alt="check" class="results-table-text-block-img" style="margin-top: 50px;">
            <div class="results-table-text-block-desc">
              <p class="results-table-text-block-p-bold">
                Какие Архетипы являются активными у вас сейчас:
              </p>
              <p class="results-table-text-block-p">
                какие стратегии приносят вам в жизни больше результатов - ваши внутренние опоры
              </p>
            </div>
          </div>
          <div class="results-table-text-block">
            <img src="../assets/images/check.svg" alt="check" class="results-table-text-block-img" style="margin-top: 80px;">
            <div class="results-table-text-block-desc">
              <p class="results-table-text-block-p-bold">
                Ресурсы каких Архетипов скрыты от вас и не дают вам энергию:
              </p>
              <p class="results-table-text-block-p">
                где скрыты мощные ресурсы и возможности, которые важно вывести из тени
              </p>
            </div>
          </div>
          <div class="results-table-text-block">
            <img src="../assets/images/check.svg" alt="check" class="results-table-text-block-img" style="margin-top: 50px;">
            <div class="results-table-text-block-desc">
              <p class="results-table-text-block-p-bold">
                Как выглядит ваше реальное колесо баланса:
              </p>
              <p class="results-table-text-block-p">
                как работают сейчас все сферы жизни - на что важно обратить внимание и как себя сбалансировать
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="results-mobile-table" style="display: none;">
        <div class="results-mobile-table-block">
          <img src="../assets/images/mobile/check.svg" alt="check" class="results-mobile-table-block-check" style="margin-top: 50px;">
          <div class="results-mobile-table-block-text">
            <p class="results-mobile-table-block-text-p-bold">
              Какие Архетипы являются активными у вас сейчас:
            </p>
            <p class="results-mobile-table-block-text-p">
              какие стратегии приносят вам в жизни больше результатов - ваши внутренние опоры
            </p>
          </div>
        </div>
        <img src="../assets/images/mobile/line.svg" alt="line" class="results-mobile-table-line">
        <div class="results-mobile-table-block">
          <img src="../assets/images/mobile/check.svg" alt="check" class="results-mobile-table-block-check" style="margin-top: 50px;">
          <div class="results-mobile-table-block-text">
            <p class="results-mobile-table-block-text-p-bold">
              Ресурсы каких Архетипов скрыты от вас и не дают вам энергию:
            </p>
            <p class="results-mobile-table-block-text-p">
              где скрыты мощные ресурсы и возможности, которые важно вывести из тени
            </p>
          </div>
        </div>
        <img src="../assets/images/mobile/line.svg" alt="line" class="results-mobile-table-line">
        <div class="results-mobile-table-block">
          <img src="../assets/images/mobile/check.svg" alt="check" class="results-mobile-table-block-check" style="margin-top: 32px;">
          <div class="results-mobile-table-block-text">
            <p class="results-mobile-table-block-text-p-bold">
              Как выглядит ваше реальное колесо баланса:
            </p>
            <p class="results-mobile-table-block-text-p">
              как работают сейчас все сферы жизни - на что важно обратить внимание и как себя сбалансировать
            </p>
          </div>
        </div>
      </div>
      <ReviewSlider class="slider" />
      <img src="../assets/images/grid.svg" alt="grid" class="subscribe-grid">
      <div class="subscribe" id="subscribe">
        <h3 class="subscribe-h3">
          ПРОЙДИТЕ ТЕСТ и получите
          <span style="color: #FEC7B6">
            персональный разбор результатов
          </span>
        </h3>
        <h3 class="subscribe-mobile-h3" style="display: none">
          ПРОЙДИТЕ ТЕСТ и получите
          <span style="color: #FEC7B6">
            персональный разбор результатов
          </span>
        </h3>
        <img
            src="../assets/images/mobile/grid.svg"
            alt="grid"
            class="subscribe-mobile-grid"
            style="display:none;"
        >
      </div>
      <div class="subscribe-button">
        <a @click="redirectToInstructions" class="subscribe-button-form-submit button">
          ПРОЙТИ ТЕСТ
        </a>
      </div>
    </div>
    <footer class="footer">
      <img
          src="../assets/images/mobile/line.svg"
          alt="line"
          class="footer-mobile-line"
          style="display:none;"
      >
      <div class="footer-side">
        <p class="footer-p">
          ИП Завадская А.А.
        </p>
        <p class="footer-p">
          ОГРНИП:
          <span class="footer-span">
              319237500089140
            </span>
        </p>
        <p class="footer-p">
          ИНН:
          <span class="footer-span">
              381403220585
            </span>
        </p>
      </div>
      <div class="footer-side">
        <p class="footer-p">
          <a href="https://zavadskaya.online/contacts" target="_blank">СЛУЖБА ПОДДЕРЖКИ</a>
        </p>
        <a href="https://zavadskaya.online/politika" target="_blank" class="footer-link">
          Политика конфиденциальности
        </a>
        <a href="https://zavadskaya.online/oferta" target="_blank" class="footer-link">
          Публичная оферта
        </a>
      </div>
    </footer>
  </body>
</template>

<script>
import Cookies from 'js-cookie';
import router from "@/router";
import ReviewSlider from "@/components/ReviewSlider";

export default {
  name: "HomeView",
  components: {ReviewSlider},
  data() {
    return {
      isPaid: 0,
      session: Cookies.get("session"),
      form: {
        email: '',
        isDisabled: false,
        errors: []
      },
    }
  },
  methods: {
    async redirectToInstructions() {
      await router.push({name: 'instructions'});
    }
  },
  async mounted() {
    if (this.session === undefined) {
      setSession(this);
    } else {
      let res = await this.axios.post("https://dev.annazavadskaya.ru:3000/api/user/session/check", {id: this.session});

      if (res.data === false) {
        setSession(this);
      } else {
        this.axios.post('https://dev.annazavadskaya.ru:3000/api/user/payment/get', { id: this.session })
            .then((res) => {
              if (res.data) {
                this.isPaid = 1;
              }
            });
      }
    }
  },
}

function setSession(app) {
  app.axios.get('https://dev.annazavadskaya.ru:3000/api/user/session/get').then((res) => {
    Cookies.set('session', res.data, {expires: 365});
    app.session = res.data;
  });
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/home.scss";
</style>
