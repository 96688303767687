<template>
  <body>
  <header>
    <h1>РАСШИРЕННЫЕ РЕЗУЛЬТАТЫ</h1>
  </header>
  <div class="results">
    <!-- Левая колонка: диаграмма и видео блок -->
    <div class="results-left">
      <!-- Диаграмма (десктоп) -->
      <div class="diagram">
        <img src="../assets/images/instructions-diagram.svg" alt="diagram" class="diagram-circle" />
        <!-- Изображения архетипов -->
        <img
            v-for="item in archetypesDiagram"
            :key="item.key"
            :src="require(`@/assets/images/diagram/${item.img}`)"
            alt="diagram"
            :class="item.cssClass"
            :style="{ zIndex: diagram[item.key].index - 1 }"
        />
        <!-- Блоки pouring -->
        <div
            v-for="item in archetypesDiagram"
            :key="item.key + '-pouring'"
            :class="`diagram-circle-pouring ${item.cssClass}-block`"
            :style="{
              zIndex: diagram[item.key].index,
              boxShadow: '0 0 0 ' + diagram[item.key].width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <!-- Вывод числовых значений -->
        <div class="diagram-circle-numbers">
          <p v-for="item in archetypesDiagram" :key="item.key + '-num'">
            {{ archetypes[item.key] }}
          </p>
        </div>
      </div>
      <!-- Диаграмма (мобильная) -->
      <div class="main-mobile-diagram" style="display: none;">
        <img src="../assets/images/instructions-diagram.svg" alt="diagram" class="main-mobile-diagram-circle" />
        <img
            v-for="item in archetypesDiagram"
            :key="item.key + '-mobile-img'"
            :src="require(`@/assets/images/diagram/${item.img}`)"
            alt="diagram"
            :class="item.cssClass"
            :style="{ zIndex: diagram[item.key].index - 1 }"
        />
        <div
            v-for="item in archetypesDiagram"
            :key="item.key + '-mobile-pouring'"
            :class="`diagram-circle-pouring ${item.cssClass}-block`"
            :style="{
              zIndex: diagram[item.key].index,
              boxShadow: '0 0 0 ' + diagram[item.key].mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div class="diagram-circle-numbers">
          <p v-for="item in archetypesDiagram" :key="item.key + '-mobile-num'">
            {{ archetypes[item.key] }}
          </p>
        </div>
      </div>
      <!-- Видео блок для скачивания гайда -->
      <div class="results-left-video">
        <img src="../assets/images/results-anna-with-book1.webp" alt="anna">
        <div class="results-left-video-block">
          <h3>ИНТЕГРАЦИЯ ЛИЧНОСТИ 12D</h3>
          <hr class="hr">
          <p>
            &#10004; Системное исследование Вашей уникальности, которое дает вам целостное и ценностное понимание себя <br><br>
            &#10004; Помогает выстроить потоковый стиль самореализации и проявленности! <br><br>
          </p>
          <a href="https://zavadskaya.online/rasp12d" target="_blank" class="button">ПОДРОБНЕЕ</a>
        </div>
      </div>
    </div>
    <!-- Правая колонка: индикаторы -->
    <div class="results-right">
      <div
          class="results-right-block"
          v-for="indicator in filteredIndicators"
          :key="indicator.key"
      >
        <h3 v-if="indicator.key === 'potentialActivation'">Активация потенциала</h3>
        <h3 v-else-if="indicator.key === 'internalImbalance'">Внутренний дисбаланс</h3>
        <h3 v-else-if="indicator.key === 'lifeManagement'">Потоковость</h3>
        <h3 v-else-if="indicator.key === 'easeOfLife'">Проявленность</h3>
        <hr class="hr">
        <p class="results-right-block-p">
          {{ indicator.text }}
          <span v-if="indicator.link">
              <a :href="indicator.link" style="color: #fec7b6">{{ indicator.textLink }}</a>
              {{ indicator.nextText }}
            </span>
        </p>
        <img
            src="../assets/images/results-hexagon.svg"
            alt="hexagon"
            :style="(indicator.key==='internalImbalance' || indicator.key==='lifeManagement') ? 'top: -23px' : ''"
        >
        <p class="results-right-block-percent">{{ indicator.percent }}%</p>
      </div>

      <div class="results-right-mobile-video" style="display: none;">
        <div class="results-right-mobile-video-block">
          <h3>ИНТЕГРАЦИЯ ЛИЧНОСТИ 12D</h3>
          <hr class="hr">
          <p>
            &#10004; Системное исследование Вашей уникальности, которое дает вам целостное и ценностное понимание себя <br><br>
            &#10004; Помогает выстроить потоковый стиль самореализации и проявленности!
          </p>
          <a href="https://zavadskaya.online/rasp12d" target="_blank" class="button">
            ПОДРОБНЕЕ
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-results">
    <div class="bottom-results-block">
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="bottom-results-block-percent">{{ indicators.selfActualization.percent }}%</p>
      <h3>Взаимодействие</h3>
      <hr class="hr">
      <p class="bottom-results-block-p" v-html="indicators.selfActualization.text">
      </p>
    </div>
    <div class="bottom-results-block">
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="bottom-results-block-percent">{{ indicators.safety.percent }}%</p>
      <h3>Стабильность</h3>
      <hr class="hr">
      <p class="bottom-results-block-p" v-html="indicators.safety.text"></p>
    </div>
    <div class="bottom-results-block">
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="bottom-results-block-percent">{{ indicators.changes.percent }}%</p>
      <h3>Изменения</h3>
      <hr class="hr">
      <p class="bottom-results-block-p" v-html="indicators.changes.text"></p>
    </div>
  </div>
  <!-- Блок для ПК: топ-архетипы (сильные) -->
  <h2 class="center-h2">Ваши сильные архетипы:</h2>
  <div class="archetypes">
    <div class="archetypes-block" v-for="(value, key) in enabledBlocks" :key="key">
      <div class="archetypes-block-image">
        <img :src="`https://annazavadskaya.ru/icons/${value.image}`" :alt="key">
      </div>
      <hr class="hr">
      <p class="archetypes-block-percent">{{ value.points }}</p>
      <h3 v-html="value.name + ':'"></h3>
      <p class="archetypes-block-p">
        {{ value.text }}<br>
        <a :href="value.link" target="_blank"><b>Перейти к описанию >>></b></a>
      </p>
    </div>
  </div>
  <!-- Новый блок для ПК: минимальные (слабые) архетипы -->
  <h2 class="center-h2">Какие архетипы важно активировать:</h2>
  <div class="archetypes">
    <div class="archetypes-block" v-for="block in minBlocks" :key="block.key">
      <div class="archetypes-block-image">
        <img :src="`https://annazavadskaya.ru/icons/${block.image}`" :alt="block.key">
      </div>
      <hr class="hr">
      <p class="archetypes-block-percent">{{ block.points }}</p>
      <h3 v-html="block.name + ':'"></h3>
      <p class="archetypes-block-p">
        {{ block.text }}<br>
        <a :href="block.link" target="_blank"><b>Перейти к описанию >>></b></a>
      </p>
    </div>
  </div>
  <!-- Нижние блоки для мобильной версии -->
  <div class="bottom-results-mobile" style="display: none;">
    <h3 style="margin-top: 46px; margin-bottom: 6px;">БАЗОВЫЙ ВЕКТОР ЖИЗНИ</h3>
    <div class="bottom-results-mobile-block">
      <h3>ВЗАИМОДЕЙСТВИЕ</h3>
      <hr class="hr">
      <p class="bottom-results-mobile-block-p" v-html="indicators.selfActualization.text"></p>
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="results-right-block-percent">{{ indicators.selfActualization.percent }}%</p>
    </div>
    <div class="bottom-results-mobile-block">
      <h3>ИЗМЕНЕНИЯ</h3>
      <hr class="hr">
      <p class="bottom-results-mobile-block-p" v-html="indicators.changes.text"></p>
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="results-right-block-percent">{{ indicators.changes.percent }}%</p>
    </div>
    <div class="bottom-results-mobile-block">
      <h3>СТАБИЛЬНОСТЬ</h3>
      <hr class="hr">
      <p class="bottom-results-mobile-block-p" v-html="indicators.safety.text"></p>
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="results-right-block-percent">{{ indicators.safety.percent }}%</p>
    </div>
    <h3>ВЕДУЩИЕ СТРАТЕГИИ ЛИДЕРСТВА</h3>
    <div class="bottom-results-mobile-archetypes">
      <div class="bottom-results-mobile-archetypes-info">
        <!-- Мобильный блок для топ-архетипов (как раньше) -->
        <div
            class="bottom-results-mobile-archetypes-info-block"
            v-for="(value, key) in mobileBlocks"
            :key="key"
        >
          <p class="bottom-results-mobile-archetypes-info-block-percent">{{ value.points }}</p>
          <hr class="hr">
          <div>
            <div class="bottom-results-mobile-archetypes-info-block-div">
              <img :src="`https://annazavadskaya.ru/icons/mobile/${value.image}`" :alt="key">
            </div>
            <p class="bottom-results-mobile-archetypes-info-block-p" v-html="value.name"></p>
          </div>
          <Transition name="mobile-block">
            <div class="bottom-results-mobile-archetypes-describe">
              <div :style="{ right: '268px' }"></div>
              <p>
                {{ value.text }}<br>
                <a :href="value.link" target="_blank"><b>Перейти к описанию >>></b></a>
              </p>
            </div>
          </Transition>
        </div>
      </div>
    </div>
    <!-- Новый мобильный блок для минимальных (слабых) архетипов -->
    <div class="bottom-results-mobile-archetypes">
      <h3 class="center-h2-mobile">Какие архетипы важно активировать:</h3>
      <div class="bottom-results-mobile-archetypes-info">
        <div
            class="bottom-results-mobile-archetypes-info-block"
            v-for="(value, key) in minMobileBlocks"
            :key="key"
        >
          <p class="bottom-results-mobile-archetypes-info-block-percent">{{ value.points }}</p>
          <hr class="hr">
          <div>
            <div class="bottom-results-mobile-archetypes-info-block-div">
              <img :src="`https://annazavadskaya.ru/icons/mobile/${value.image}`" :alt="key">
            </div>
            <p class="bottom-results-mobile-archetypes-info-block-p" v-html="value.name"></p>
          </div>
          <Transition name="mobile-block">
            <div class="bottom-results-mobile-archetypes-describe">
              <div :style="{ right: '268px' }"></div>
              <p>
                {{ value.text }}<br>
                <a :href="value.link" target="_blank"><b>Перейти к описанию >>></b></a>
              </p>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
  <!-- Блок с предложением скачать план -->
  <div class="book">
    <hr class="hr">
    <div class="book-light"></div>
    <img src="../assets/images/results-anna-bottom.webp" alt="Анна Завадская" class="book-img">
    <div class="book-set">
      <div class="book-set-block">
        <p>
          Присоединяйтесь в телеграм, чтобы узнать как через архетипы двигаться к своим целям в потоке - легко, на энергии, в любви к себе и миру
        </p>
        <a href="https://t.me/+alyfiQj43tphYzc6" target="_blank" class="button">ПЕРЕЙТИ В ТГ</a>
      </div>
    </div>
    <hr class="hr book-hr">
  </div>
  <!-- Мобильная версия блока с планом -->
  <div class="book-mobile" style="display: none;">
    <p>
      Присоединяйтесь в телеграм, чтобы узнать как через архетипы двигаться к своим целям в потоке - легко, на энергии, в любви к себе и миру
    </p>
    <img src="../assets/images/mobile/results-arrow-bottom.svg" alt="arrow">
    <a href="https://t.me/+alyfiQj43tphYzc6" target="_blank" class="button">ПЕРЕЙТИ В ТГ</a>
    <div class="book-mobile-anna">
      <div class="book-mobile-anna-light"></div>
      <img src="../assets/images/mobile/results-anna.webp" alt="anna" class="book-mobile-anna-img">
    </div>
  </div>
  <footer>
    <div class="footer">
      <div class="footer-text">
        © 2019 zavadskaya.online <br>
        Авторские права защищены <br>
        Копирование запрещено
      </div>
      <div class="footer-links">
        <a href="https://t.me/zavadskaya12D" target="_blank">
          <img src="../assets/images/results-tg.svg" alt="Telegram" class="footer-links">
        </a>
      </div>
    </div>
  </footer>
  <div class="mobile-links" style="display: none;">
    <div>
      <a href="https://t.me/zavadskaya12D" target="_blank">
        <img src="../assets/images/results-tg.svg" alt="Telegram">
      </a>
    </div>
  </div>
  </body>
</template>

<script>
import router from "@/router";

export default {
  name: "ResultsView",
  data() {
    return {
      mobileText: [
        'Стратегия легкости и простоты, спонтанности и позитива.\nПомогает вам жить в согласии с собой, радоваться каждому моменту и вдохновлять окружающих.',
        'Стратегия развития, свободы, гибкой подстройки под меняющиеся условия.\nПомогает вам идти в ногу со временем и использовать новые возможности.',
        'Стратегия мудрости, аналитики.\nПомогает принимать взвешенные решения, видеть всю картину в целом, исследовать взаимосвязи причин и следствий.',
        'Стратегия достижений - источник вашей активности, смелости, уверенности в себе.\nПомогает вам проходить любые препятствия и достигать своих целей.',
        'Стратегия кардинальных изменений, помогает разрушать неработающее и устаревшее.\nВносит перемены, тотальное освобождение, квантовый рост.',
        'Стратегия осознанности и работы с непроявленным.\nПомогает вам управлять состояниями, трансформировать мышление, влиять на происходящее.',
        'Стратегия внимательного отношения, заботы и помощи.\nПомогает вам окружать близких комфортом, уютом, создавать теплую поддерживающую атмосферу.',
        'Стратегия создания нового, нестандартных решений и креатива.\nРождает творческий импульс и помогает уникально самовыражаться через свое дело жизни.',
        'Стратегия управления, контроля и власти.\nПомогает создавать финансовую стабильность, процветание, системно выстраивать благополучие.',
        'Стратегия дружеского общения на равных.\nПомогает вам создавать успешное взаимодействие с людьми, широкий круг общения, синергию в партнерстве.',
        'Стратегия гедонизма и наслаждения жизнью.\nПомогает вам быть привлекательными, сексуальными, притягивать внимание и восхищение окружающих.',
        'Стратегия развлечений и ярких впечатлений.\nПомогает снижать напряжение, применять игровой подход к решению задач, смеяться и радоваться жизни.'
      ],
      mobileLinks: [
        'https://zavadskaya.online/arxetip-nevinnyj-prostodushnyj',
        'https://zavadskaya.online/arxetip-iskatel-strannik',
        'https://zavadskaya.online/arxetip-mudrec-filosof',
        'https://zavadskaya.online/arxetip-geroj-voin',
        'https://zavadskaya.online/arxetip-buntar-izgoj',
        'https://zavadskaya.online/arxetip-mag-volshebnik',
        'https://zavadskaya.online/arxetip-zabotlivyj-xranitel',
        'https://zavadskaya.online/arxetip-tvorec',
        'https://zavadskaya.online/arxetip-pravitel',
        'https://zavadskaya.online/arxetip-slavnyj-malyj',
        'https://zavadskaya.online/arxetip-lyubovnik-estet',
        'https://zavadskaya.online/arxetip-shut'
      ],
      // Массив для циклического рендеринга диаграммы
      archetypesDiagram: [
        { key: "nevin", img: "nevin.svg", cssClass: "diagram-circle-nevin" },
        { key: "isk", img: "isk.svg", cssClass: "diagram-circle-isk" },
        { key: "mudr", img: "intel.svg", cssClass: "diagram-circle-intel" },
        { key: "hero", img: "hero.svg", cssClass: "diagram-circle-hero" },
        { key: "bunt", img: "izmen.svg", cssClass: "diagram-circle-izmen" },
        { key: "mag", img: "duh.svg", cssClass: "diagram-circle-duh" },
        { key: "zabot", img: "zabot.svg", cssClass: "diagram-circle-zabot" },
        { key: "tvor", img: "tvor.svg", cssClass: "diagram-circle-tvor" },
        { key: "prav", img: "prav.svg", cssClass: "diagram-circle-prav" },
        { key: "mal", img: "mal.svg", cssClass: "diagram-circle-mal" },
        { key: "love", img: "love.svg", cssClass: "diagram-circle-love" },
        { key: "jester", img: "jester.svg", cssClass: "diagram-circle-jester" },
      ],
      mobileBlocks: {},
      blocks: {
        bunt: {
          name: "Бунтарь",
          points: 0,
          text: 'Стратегия кардинальных изменений, помогает разрушать неработающее и устаревшее.\nВносит перемены, тотальное освобождение, квантовый рост.',
          link: 'https://zavadskaya.online/arxetip-buntar-izgoj',
          image: "results-bunt.svg",
          isEnabled: false
        },
        hero: {
          name: "Герой",
          points: 0,
          text: 'Стратегия достижений - источник вашей активности, смелости, уверенности в себе.\nПомогает вам проходить любые препятствия и достигать своих целей.',
          link: 'https://zavadskaya.online/arxetip-geroj-voin',
          image: "results-hero.svg",
          isEnabled: false
        },
        isk: {
          name: "Искатель",
          points: 0,
          text: 'Стратегия развития, свободы, гибкой подстройки под меняющиеся условия.\nПомогает вам идти в ногу со временем и использовать новые возможности.',
          link: 'https://zavadskaya.online/arxetip-iskatel-strannik',
          image: "results-isk.svg",
          isEnabled: false
        },
        jester: {
          name: "Шут",
          points: 0,
          text: 'Стратегия развлечений и ярких впечатлений.\nПомогает снижать напряжение, применять игровой подход к решению задач, смеяться и радоваться жизни.',
          link: 'https://zavadskaya.online/arxetip-shut',
          image: "results-shut.svg",
          isEnabled: false
        },
        love: {
          name: "Любовник",
          points: 0,
          text: 'Стратегия гедонизма и наслаждения жизнью.\nПомогает вам быть привлекательными, сексуальными, притягивать внимание и восхищение окружающих.',
          link: 'https://zavadskaya.online/arxetip-lyubovnik-estet',
          image: "results-love.svg",
          isEnabled: false
        },
        mag: {
          name: "Маг",
          points: 0,
          text: 'Стратегия осознанности и работы с непроявленным.\nПомогает вам управлять состояниями, трансформировать мышление, влиять на происходящее.',
          link: 'https://zavadskaya.online/arxetip-mag-volshebnik',
          image: "results-mag.svg",
          isEnabled: false
        },
        mal: {
          name: "Славный Малый",
          points: 0,
          text: 'Стратегия дружеского общения на равных.\nПомогает вам создавать успешное взаимодействие с людьми, широкий круг общения, синергию в партнерстве.',
          link: 'https://zavadskaya.online/arxetip-slavnyj-malyj',
          image: "results-mal.svg",
          isEnabled: false
        },
        mudr: {
          name: "Мудрец",
          points: 0,
          text: 'Стратегия мудрости, аналитики.\nПомогает принимать взвешенные решения, видеть всю картину в целом, исследовать взаимосвязи причин и следствий.',
          link: 'https://zavadskaya.online/arxetip-mudrec-filosof',
          image: "results-mudr.svg",
          isEnabled: false
        },
        nevin: {
          name: "Невинный",
          points: 0,
          text: 'Стратегия легкости и простоты, спонтанности и позитива.\nПомогает вам жить в согласии с собой, радоваться каждому моменту и вдохновлять окружающих.',
          link: 'https://zavadskaya.online/arxetip-nevinnyj-prostodushnyj',
          image: "results-nevin.svg",
          isEnabled: false
        },
        prav: {
          name: "Правитель",
          points: 0,
          text: 'Стратегия управления, контроля и власти.\nПомогает создавать финансовую стабильность, процветание, системно выстраивать благополучие.',
          link: 'https://zavadskaya.online/arxetip-pravitel',
          image: "results-prav.svg",
          isEnabled: false
        },
        tvor: {
          name: "Творец",
          points: 0,
          text: 'Стратегия создания нового, нестандартных решений и креатива.\nРождает творческий импульс и помогает уникально самовыражаться через свое дело жизни.',
          link: 'https://zavadskaya.online/arxetip-tvorec',
          image: "results-tvor.svg",
          isEnabled: false
        },
        zabot: {
          name: "Заботливый",
          points: 0,
          text: 'Стратегия внимательного отношения, заботы и помощи.\nПомогает вам окружать близких комфортом, уютом, создавать теплую поддерживающую атмосферу.',
          link: 'https://zavadskaya.online/arxetip-zabotlivyj-xranitel',
          image: "results-zabot.svg",
          isEnabled: false
        },
      },
      currentTriangle: 0,
      currentDisplay: { one: false, two: false, three: false, four: false },
      mobileTriangle: [264, 148, 34],
      mobileState: -1,
      session: this.$route.params.session,
      indicators: {
        potentialActivation: {
          key: 'potentialActivation',
          percent: 0,
          text: '',
          nextText: '',
          link: '',
          textLink: ''
        },
        internalImbalance: {
          key: 'internalImbalance',
          percent: 0,
          text: '',
          nextText: '',
          link: '',
          textLink: ''
        },
        lifeManagement: {
          key: 'lifeManagement',
          percent: 0,
          text: '',
          nextText: '',
          link: '',
          textLink: ''
        },
        easeOfLife: {
          key: 'easeOfLife',
          percent: 0,
          text: '',
          nextText: '',
          link: '',
          textLink: ''
        },
        selfActualization: {
          key: 'selfActualization',
          percent: 0,
          text: ''
        },
        changes: {
          key: 'changes',
          percent: 0,
          text: ''
        },
        safety: {
          key: 'safety',
          percent: 0,
          text: ''
        },
      },
      archetypes: {
        nevin: 0,
        mal: 0,
        hero: 0,
        zabot: 0,
        isk: 0,
        bunt: 0,
        love: 0,
        tvor: 0,
        prav: 0,
        mag: 0,
        mudr: 0,
        jester: 0
      },
      diagram: {
        nevin: { index: 1, width: 1, mobileWidth: 1 },
        mal: { index: 1, width: 1, mobileWidth: 1 },
        hero: { index: 1, width: 1, mobileWidth: 1 },
        zabot: { index: 1, width: 1, mobileWidth: 1 },
        isk: { index: 1, width: 1, mobileWidth: 1 },
        bunt: { index: 1, width: 1, mobileWidth: 1 },
        love: { index: 1, width: 1, mobileWidth: 1 },
        tvor: { index: 1, width: 1, mobileWidth: 1 },
        prav: { index: 1, width: 1, mobileWidth: 1 },
        mag: { index: 1, width: 1, mobileWidth: 1 },
        mudr: { index: 1, width: 1, mobileWidth: 1 },
        jester: { index: 1, width: 1, mobileWidth: 1 },
        currentIndex: 2,
        circle: [135, 120, 109, 98, 86, 74, 62, 51, 39, 28, 0],
        circleMobile: [73, 64, 57, 50, 43, 36, 29, 22, 15, 8, 0],
      },
      isVisiblePopup: false,
    };
  },
  computed: {
    enabledBlocks() {
      return Object.entries(this.blocks)
          .filter(([, block]) => block.isEnabled)
          .sort(([, a], [, b]) => b.points - a.points)
          .reduce((acc, [key, block]) => {
            acc[key] = block;
            return acc;
          }, {});
    },
    filteredIndicators() {
      const allowed = ['potentialActivation', 'internalImbalance', 'lifeManagement', 'easeOfLife'];
      return allowed
          .map(key => ({ key, ...this.indicators[key] }))
          .filter(indicator => indicator !== undefined);
    },
    minBlocks() {
      // Для ПК – 4 архетипа с минимальными баллами
      return Object.entries(this.blocks)
          .sort(([, a], [, b]) => a.points - b.points)
          .slice(0, 4)
          .map(([key, block]) => ({ key, ...block }));
    },
    minMobileBlocks() {
      // Для мобильной версии – 3 архетипа с минимальными баллами
      return Object.entries(this.blocks)
          .sort(([, a], [, b]) => a.points - b.points)
          .slice(0, 3)
          .map(([key, block]) => ({ key, ...block }));
    }
  },
  async beforeMount() {
    await isActivated(this);
    await getResult(this);
  },
  methods: {
    getMobileBlocks() {
      const enabledItems = Object.entries(this.blocks)
          .filter(([, item]) => item.isEnabled)
          .sort(([, a], [, b]) => b.points - a.points)
          .slice(0, 3);
      if (enabledItems.length >= 4) {
        enabledItems.splice(3, 1);
      }
      return Object.fromEntries(enabledItems);
    },
    closePopup() {
      this.isVisiblePopup = false;
    }
  }
};

async function isActivated(app) {
  try {
    const res = await app.axios.post("https://dev.annazavadskaya.ru:3000/api/user/activate/get", { id: app.session });
    if (!res.data) {
      router.push({ name: 'home' });
    }
  } catch (error) {
    console.error("Activation error:", error);
  }
}

async function getResult(app) {
  try {
    const res = await app.axios.post("https://dev.annazavadskaya.ru:3000/api/results/", { id: app.session });
    app.archetypes = { ...res.data };
    if (
        app.archetypes.nevin === 0 &&
        app.archetypes.mal === 0 &&
        app.archetypes.hero === 0 &&
        app.archetypes.zabot === 0
    ) {
      router.push({ name: 'home' });
      return;
    }
    // Обновляем баллы для всех блоков
    Object.keys(app.blocks).forEach(key => {
      app.blocks[key].points = app.archetypes[key];
    });
    const conclusion = await findTop(app.archetypes, 4);
    conclusion.forEach((key) => {
      app.blocks[key].isEnabled = true;
    });
    app.mobileBlocks = app.getMobileBlocks();
    calculateDiagram(app);
    app.indicators.potentialActivation.percent = calculatePotentialActivation({ ...app.archetypes });
    app.indicators.potentialActivation.text = textPotentialActivation(app.indicators.potentialActivation.percent, app);
    app.indicators.internalImbalance.percent = calculateInternalImbalance({ ...app.archetypes });
    app.indicators.internalImbalance.text = textInternalImbalance(app.indicators.internalImbalance.percent, app);
    app.indicators.lifeManagement.percent = calculateLifeManagement({ ...app.archetypes });
    app.indicators.lifeManagement.text = textLifeManagement(app.indicators.lifeManagement.percent, app);
    app.indicators.easeOfLife.percent = calculateEaseOfLife({ ...app.archetypes });
    app.indicators.easeOfLife.text = textEaseOfLife(app.indicators.easeOfLife.percent, app);
    app.indicators.selfActualization.percent = calculateSelfActualization({ ...app.archetypes });
    app.indicators.selfActualization.text = `Глобальный вектор на взаимодействие с миром.
    Показывает, насколько активно вы коммуницируете и сближаетесь с окружающими.`;
    app.indicators.changes.percent = calculateChanges({ ...app.archetypes });
    app.indicators.changes.text = `Глобальный вектор на изменения и прорывной рост.
    Показывает, насколько активно вы вносите изменения и управляете ими.`;
    app.indicators.safety.percent = calculateSafety({ ...app.archetypes });
    app.indicators.safety.text = `Глобальный вектор на контроль и безопасность.
    Показывает, насколько активно вы стремитесь создать благополучие и процветание.`;
  } catch (error) {
    console.error("Error in getResult:", error);
  }
}

function findTop(obj, topVal) {
  return Object.keys(obj)
      .sort((a, b) => obj[b] - obj[a])
      .slice(0, topVal);
}

function calculatePotentialActivation({ ...data }) {
  const sum = data.nevin + data.mal + data.hero + data.zabot + data.isk +
      data.bunt + data.love + data.tvor + data.prav + data.mag + data.mudr + data.jester;
  return Math.round(sum / 12);
}

function calculateInternalImbalance({ ...data }) {
  const arr = Object.values(data);
  return Math.max(...arr) - Math.min(...arr);
}

function calculateLifeManagement({ ...data }) {
  const result = (data.nevin + data.mag + data.love + data.isk) / 4;
  return Math.round(result);
}

function calculateEaseOfLife({ ...data }) {
  const result = (data.love + data.jester + data.prav + data.bunt + data.mal) / 5;
  return Math.round(result);
}

function calculateSelfActualization({ ...data }) {
  const result = (data.nevin + data.mal + data.love + data.jester) / 4;
  return Math.round(result);
}

function calculateChanges({ ...data }) {
  const result = (data.hero + data.isk + data.bunt + data.mag) / 4;
  return Math.round(result);
}

function calculateSafety({ ...data }) {
  const result = (data.zabot + data.tvor + data.prav + data.mudr) / 4;
  return Math.round(result);
}

function calculateDiagram(app) {
  const keys = ["nevin", "mal", "hero", "zabot", "isk", "bunt", "love", "prav", "tvor", "mag", "mudr", "jester"];
  const floors = {};
  keys.forEach(key => {
    floors[key] = Math.floor(app.archetypes[key] / 10);
  });
  const array = keys.map(key => floors[key]);
  const arrayText = [...keys];
  for (let i = 0; i < 11; i++) {
    const minVal = Math.min(...array);
    const indices = [];
    if (minVal !== 15) {
      array.forEach((el, index, arr) => {
        if (el === minVal) {
          indices.push(index);
          arr[index] = 15;
        }
      });
    }
    if (indices.length) {
      if (indices.length > 1) {
        indices.forEach(idx => {
          app.diagram[arrayText[idx]].index = app.diagram.currentIndex + 2;
        });
        app.diagram.currentIndex += 2;
      } else {
        app.diagram[arrayText[indices[0]]].index = app.diagram.currentIndex;
      }
    }
    app.diagram.currentIndex += 2;
  }
  keys.forEach(key => {
    app.diagram[key].width = app.diagram.circle[floors[key]];
    app.diagram[key].mobileWidth = app.diagram.circleMobile[floors[key]];
  });
}

function textPotentialActivation(percent, app) {
  if (percent <= 50) {
    app.indicators.potentialActivation.link = 'https://zavadskaya.online/rasp12d';
    app.indicators.potentialActivation.textLink = 'можно здесь';
    app.indicators.potentialActivation.nextText = '';
    return "Ваш потенциал пока не раскрыт. Вам необходимо понять свою уникальность! Собрать себя в красивое целое ";
  } else if (percent >= 51 && percent <= 80) {
    app.indicators.potentialActivation.link = 'https://zavadskaya.online/checklist';
    app.indicators.potentialActivation.textLink = 'можно здесь';
    app.indicators.potentialActivation.nextText = '';
    return "Ваш потенциал сдерживают спящие архетипы личности. Активировать заблокированные ресурсы ";
  } else {
    app.indicators.potentialActivation.link = 'https://zavadskaya.online/rasp12d';
    app.indicators.potentialActivation.textLink = 'можно здесь';
    app.indicators.potentialActivation.nextText = '';
    return "Супер, потенциал личности раскрыт отлично, но пора расширять горизонты! Поставить новые вдохновляющие цели и увидеть вектор роста ";
  }
}

function textInternalImbalance(percent, app) {
  if (percent <= 20) {
    app.indicators.internalImbalance.link = '';
    app.indicators.internalImbalance.textLink = '';
    app.indicators.internalImbalance.nextText = '';
    return "Держите отличный баланс! Обратите внимание на показатель \"Активация потенциала\" - если процент небольшой, то важно дополнительно активировать все свои Архетипы, сохраняя уже имеющийся баланс.";
  } else if (percent >= 21 && percent <= 50) {
    app.indicators.internalImbalance.link = 'https://zavadskaya.online/checklist';
    app.indicators.internalImbalance.textLink = 'больше гармонии внутри';
    app.indicators.internalImbalance.nextText = '';
    return "Средняя балансировка, нужно обратить внимание на спящие Архетипы и включить их ресурсы! Тогда система начнет выравниваться и вы будете чувствовать ";
  } else {
    app.indicators.internalImbalance.link = 'https://zavadskaya.online/checklist';
    app.indicators.internalImbalance.textLink = 'можно здесь';
    app.indicators.internalImbalance.nextText = '';
    return "Cистема достаточно разбалансирована, это может привести к выгоранию и внутренним конфликтам. Узнать, как активировать спящие Архетипы ";
  }
}

function textLifeManagement(percent, app) {
  if (percent <= 50) {
    app.indicators.lifeManagement.link = 'https://zavadskaya.online/3keys';
    app.indicators.lifeManagement.textLink = 'здесь';
    app.indicators.lifeManagement.nextText = '';
    return "Слабый поток и очень много внутреннего напряжения. Вам важно создать базовое поддерживающее состояние потока внутри себя. Подробнее ";
  } else if (percent >= 51 && percent <= 80) {
    app.indicators.lifeManagement.link = 'https://zavadskaya.online/checklist';
    app.indicators.lifeManagement.textLink = 'можно здесь';
    app.indicators.lifeManagement.nextText = '';
    return "Средняя потоковость, в вашей системе есть напряжение, которое важно убрать. Разблокировать спящие архетипы ";
  } else {
    app.indicators.lifeManagement.link = '';
    app.indicators.lifeManagement.textLink = '';
    app.indicators.lifeManagement.nextText = '';
    return "Вы двигаетесь по жизни в потоке, успевая получать удовольствие от жизни. Умеете задействовать нужный ресурс и оставаться на связи с Собой!";
  }
}

function textEaseOfLife(percent, app) {
  if (percent <= 50) {
    app.indicators.easeOfLife.link = 'https://zavadskaya.online/3keys';
    app.indicators.easeOfLife.textLink = 'по этой ссылке';
    app.indicators.easeOfLife.nextText = '';
    return "Вы почти не проявлены в мир, ваш потенциал и призвание не реализуются. Обязательно начните возвращаться к Себе с помощью простых практик ";
  } else if (percent >= 51 && percent <= 80) {
    app.indicators.easeOfLife.link = 'https://zavadskaya.online/checklist';
    app.indicators.easeOfLife.textLink = 'этой ссылке';
    app.indicators.easeOfLife.nextText = '';
    return "Средняя проявленность, обратите внимание на активацию Шута, Любовника и Славного Малого по ";
  } else {
    app.indicators.easeOfLife.link = 'https://zavadskaya.online/brand_concept';
    app.indicators.easeOfLife.textLink = 'можно здесь';
    app.indicators.easeOfLife.nextText = '';
    return "Вы уверенно проявляетесь в мир, вы заметны, притягиваете внимание и восхищение окружающих! Создать звездный личный бренд ";
  }
}
</script>

<style lang="scss" scoped>
@import "/src/assets/css/results.scss";
</style>